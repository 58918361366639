// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-layout-jsx": () => import("./../src/templates/BlogPostLayout.jsx" /* webpackChunkName: "component---src-templates-blog-post-layout-jsx" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-bistrobo-jsx": () => import("./../src/pages/bistrobo.jsx" /* webpackChunkName: "component---src-pages-bistrobo-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-thanks-jsx": () => import("./../src/pages/thanks.jsx" /* webpackChunkName: "component---src-pages-thanks-jsx" */)
}

